import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import { RelatedPosts } from '../components/Posts/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { Image } from '../components/Image';
import './project.scss';
import { decodeEntities } from '../utils/helpers';
import { Header, PostFeed, FooterTopBlock } from '../components/Acf';
import Carousel from '../components/Carousel';
import { Arrow } from '../components/Svg';

export const ProjectPostTemplate = ({
  content,
  categories,
  title,
  date,
  author,
  acf
}) => {
  const { client, location, value, time, images } = acf;
  return (
    <section className="single-project">
      <div className="wrap">
        <div className="project-content">
          <RenderShortcode content={content} />
        </div>
        <div className="project-details">
          {client && <div><span className="project-details-title">Client:</span><span>{decodeEntities(client)}</span></div>}
          {location && <div><span className="project-details-title">Location:</span><span>{decodeEntities(location)}</span></div>}
          {value && <div><span className="project-details-title">Value:</span><span>{decodeEntities(value)}</span></div>}
          {time && <div><span className="project-details-title">Time:</span><span>{decodeEntities(time)}</span></div>}
        </div>
      </div>
    </section>
  )
}

const ProjectPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings, siteSettings } = data;
  if (!post) return null;
  const { title, content, featured_image_url, yoast, categories, date, author, acf } = post;
  const settings = {
    container: 'project-gallery-slider',
    nav: false,
    mouseDrag: true,
    controls: true,
    autoHeight: true,
    loop: true,
    gutter: 20,
    slideBy: 1,
    items: 1,
    controlsContainer: '.project-gallery-nav',
  };
  return (
    <Layout>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={yoast.metaDescription}
        yoast={yoast}
      />
      <Header title={title} location={location} />
      <ProjectPostTemplate
        acf={acf}
        content={content}
        categories={categories}
        title={title}
        date={date}
        author={author}
        blogSlug="projects"
      />
      {acf && acf.images && (
        <section className="project-gallery">
          <div className="wrap">
            <Carousel settings={settings}>
              {acf.images.map((image, index) => {
                return (
                  <div key={index} className="project-gallery-slide">
                    <Image src={image} position="relative" lazy={false}/>
                  </div>
                );
              })}
            </Carousel>
            <div className="project-gallery-nav">
              <button type="button" title="Prev"><Arrow direction="left" /></button>
              <button type="button" title="Next"><Arrow direction="right" /></button>
            </div>
          </div>
        </section>
      )}
      <PostFeed
        title={siteSettings.options.relatedProjectsTitle}
        introContent={siteSettings.options.relatedProjectsContent}
        contentType="project"
      />
      <FooterTopBlock useGlobal />
    </Layout>
  )
}

export default Previewable(ProjectPost, 'projects');

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        relatedProjectsTitle
        relatedProjectsContent
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      categories {
        name
        slug
      }
      author {
        name
        slug
        avatar_urls {
          wordpress_48
        }
      }
      acf {
        client
        location
        value
        time
        images {
          source_url
        }
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`
